import { ComponentProps } from "react";
import { clsx } from "clsx";

type Props = ComponentProps<"input">;

export const Input = ({ className, ...rest }: Props) => {
  return (
    <input
      {...rest}
      className={clsx(
        className,
        "min-w-56 rounded px-4 py-2 text-black bg-white",
        "flex items-center justify-center gap-2"
      )}
    />
  );
};
