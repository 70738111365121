import { ReactElement, ReactNode, cloneElement, useRef, useState } from "react";
import { Button } from "./Button";

type Props = {
  children?: ReactNode;
  target: ReactElement;
  title: string;
  description?: string;
  confirmText?: string | null;
  onConfirm?: () => Promise<void | { error?: string }>;
};

export const Dialog = ({
  children,
  target,
  title,
  description,
  confirmText = "Confirm",
  onConfirm,
}: Props) => {
  const ref = useRef<HTMLDialogElement>(null);
  const [isOpened, setIsOpened] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState<string | null>(null);

  return (
    <>
      {cloneElement(target, {
        onClick: () => {
          setIsOpened(true);
          ref.current?.showModal();
          setError(null);
        },
      })}
      <dialog
        ref={ref}
        className="backdrop:bg-black/50 bg-transparent "
        onClick={(e) => {
          if (e.target === ref.current) ref.current?.close();
        }}
        onClose={() => setIsOpened(false)}
      >
        {isOpened ? (
          <form
            className="bg-white p-4 rounded"
            onSubmit={async (e) => {
              try {
                e.preventDefault();
                setIsPending(true);
                setError(null);

                const res = await onConfirm?.();
                if (res?.error) {
                  setError(res.error);
                  setIsPending(false);
                  return;
                }

                ref.current?.close();
                setIsOpened(false);
                setIsPending(false);
              } catch (error) {
                setError(
                  error instanceof Error
                    ? error.message
                    : "Error: " + String(error)
                );
                setIsPending(false);
              }
            }}
          >
            <div className="w-full min-h-32">
              <h2>{title}</h2>
              {description ? (
                <p className="text-black mt-4 text-sm">{description}</p>
              ) : null}
              {children}
            </div>
            {error ? (
              <p className="text-red-500 text-sm my-2">{error}</p>
            ) : null}
            {confirmText != null ? (
              <div className="flex items-center justify-end">
                <Button type="submit" pending={isPending}>
                  {confirmText}
                </Button>
              </div>
            ) : null}
          </form>
        ) : null}
      </dialog>
    </>
  );
};
