import { Suspense, lazy, useRef, useState } from "react";
import clsx from "clsx";
import { Spinner } from "../utils/Spinner";

const EmojiPickWrapper = lazy(() => import("./EmojiPickWrapper"));

type Props = {
  emoji: string;
  setEmoji: (emoji: string) => void;
};

export const EmojiPickInput = ({ emoji, setEmoji }: Props) => {
  const ref = useRef<HTMLDialogElement>(null);
  const [isOpened, setIsOpened] = useState(false);

  return (
    <div>
      <dialog
        ref={ref}
        className="backdrop:bg-black/50 bg-transparent"
        onClick={(e) => {
          if (e.target === ref.current) ref.current?.close();
        }}
        onClose={() => setIsOpened(false)}
      >
        <Suspense fallback={<Spinner className="p-6" />}>
          <EmojiPickWrapper
            show={isOpened}
            onEmojiClick={(e) => {
              setEmoji(e.emoji);
              ref.current?.close();
            }}
            previewConfig={{
              defaultCaption: "Pick your emoji",
              showPreview: true,
              defaultEmoji: "💩",
            }}
            autoFocusSearch={false}
          />
        </Suspense>
      </dialog>
      <input
        required
        onClick={() => {
          ref.current?.showModal();
          setIsOpened(true);
        }}
        onFocus={(e) => e.currentTarget.blur()}
        className={clsx(
          "text-5xl cursor-pointer w-16 h-16 rounded text-center",
          {
            "bg-white": !emoji,
            "bg-transparent": !!emoji,
          }
        )}
        placeholder="❓"
        value={emoji}
        name="emoji"
        onChange={(e) => setEmoji(e.currentTarget.value)}
      />
    </div>
  );
};
