import * as v from "valibot";
import { HttpError } from "../utils/errors";
import { GetRequests, PostRequests } from "./schema";

const PATH_PREFIX = "/api";

export const post = <Path extends keyof PostRequests>(
  path: Path,
  data: v.Input<PostRequests[Path]["request"]>
): Promise<v.Output<PostRequests[Path]["response"]>> =>
  fetch(PATH_PREFIX + path, {
    method: "POST",
    body: JSON.stringify(v.parse(PostRequests[path].request, data)),
  })
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      throw new HttpError("Request failed", res.status, res.text);
    })
    .then((res) => v.parse(PostRequests[path].response, res));

export const get = <Path extends keyof GetRequests>(
  path: Path,
  params: v.Input<GetRequests[Path]["params"]>
) =>
  fetch(
    PATH_PREFIX +
      path +
      "?" +
      new URLSearchParams(v.parse(GetRequests[path].params, params)),
    { method: "GET" }
  )
    .then((res) => res.json())
    .then((res) => v.parse(GetRequests[path].response, res));
