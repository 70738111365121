import { useRequiredParam } from "../hooks/useRequiredParam";
import { Leaderboard } from "../components/game/Leaderboard";
import { Dialog } from "../components/utils/Dialog";
import { IoCheckmark, IoShareSocialOutline } from "react-icons/io5";
import { Button } from "../components/utils/Button";
import { useCopy } from "../utils/use-copy";

export const Play = () => {
  const gameId = useRequiredParam("gameId");
  const shareUrl = `${location.origin}/join/${gameId}`;
  const params = new URLSearchParams({
    url: shareUrl,
    size: "256",
    format: "svg",
  });
  const { copied, copy } = useCopy(shareUrl);

  return (
    <div>
      <div className="flex justify-end">
        <Dialog
          title="Share"
          target={
            <button aria-label="Open share dialog" className="p-4">
              <IoShareSocialOutline />
            </button>
          }
          confirmText={null}
        >
          <img src={`${location.origin}/api/qr?${params}`} />
          <div className="my-2 flex justify-center">
            <Button mode="secondary" onClick={copy}>
              {copied ? (
                <>
                  <IoCheckmark /> Copied
                </>
              ) : (
                "Copy Link"
              )}
            </Button>
          </div>
        </Dialog>
      </div>
      <Leaderboard gameId={gameId} />
    </div>
  );
};
