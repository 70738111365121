import { useMutation } from "@tanstack/react-query";
import { Button } from "../components/utils/Button";
import { post } from "../api/api";
import { useNavigate } from "react-router-dom";
import { Input } from "../components/utils/Input";
import { useState } from "react";
import { EmojiPickInput } from "../components/emoji/EmojiPickInput";
import { StartGameRequest } from "../api/schema";

const startGameFn = (params: StartGameRequest) => post("/start-game", params);

export function Home() {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [emoji, setEmoji] = useState("");

  const startGame = useMutation({ mutationFn: startGameFn });

  return (
    <div className="flex flex-col gap-8 my-10 items-center">
      <h1>Taking Part 🎖️</h1>
      {startGame.error?.message ? (
        <p className="text-red-200">{startGame.error.message}</p>
      ) : null}
      <form
        className="flex flex-col gap-4 items-center"
        onSubmit={(e) => {
          e.preventDefault();
          startGame.mutate(
            { name: name.trim(), emoji },
            { onSuccess: (res) => navigate(`/play/${res.gameId}`) }
          );
        }}
      >
        <Input
          name="name"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.currentTarget.value)}
          required
        />
        <EmojiPickInput emoji={emoji} setEmoji={setEmoji} />
        <Button type="submit" mode="primary" pending={startGame.isPending}>
          Start
        </Button>
      </form>
    </div>
  );
}
