import clsx from "clsx";
import { AddScoreRequest, LeaderboardResponse } from "../../api/schema";
import { useState } from "react";
import { Dialog } from "../utils/Dialog";
import { Input } from "../utils/Input";
import { post } from "../../api/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";

type Props = {
  player: LeaderboardResponse["players"][number] | null | undefined;
  gameId: string;
};

const addScoreFn = (params: AddScoreRequest) => post("/add-score", params);

export const PlayerRow = ({ player, gameId }: Props) => {
  const queryClient = useQueryClient();
  const addScore = useMutation({
    mutationFn: addScoreFn,
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["leaderboard", gameId] }),
  });
  const [expanded, setExpanded] = useState(false);
  const isAdmin = true;
  const [score, setScore] = useState("");
  const [title, setTitle] = useState("");

  return (
    <li>
      <div className="flex items-center justify-between">
        <div
          className={clsx("my-2.5 text-sm font-bold", {
            "bg-gray-300/30 text-gray-300/30 rounded-full select-none w-24":
              !player,
          })}
        >
          <button
            className={clsx("p-2 transition-transform", {
              "rotate-90": expanded,
            })}
            onClick={() => setExpanded(!expanded)}
          >
            ➤
          </button>
          {player?.name || "Unknown"} {player?.emoji}
          {isAdmin && player ? (
            <Dialog
              target={<button className="py-4 px-2">+</button>}
              title="Update score"
              description="Add a new the score for this player"
              onConfirm={() =>
                addScore.mutateAsync({
                  gameId,
                  playerId: player.id,
                  score: Number(score),
                  title,
                })
              }
            >
              <div className="flex flex-col gap-4 my-4">
                <label className="text-sm">
                  Title
                  <Input
                    className="border border-black/50"
                    placeholder="Title"
                    required
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </label>
                <label className="text-sm">
                  Score
                  <Input
                    className="border border-black/50"
                    placeholder="Score"
                    required
                    inputMode="numeric"
                    pattern="-?[0-9]+"
                    value={score}
                    onChange={(e) => setScore(e.target.value)}
                  />
                </label>
              </div>
            </Dialog>
          ) : null}
        </div>
        <div
          className={clsx("text-xs", {
            "bg-gray-300/30 text-gray-300/30 rounded-full select-none w-12 ":
              !player,
          })}
        >
          {player?.score ?? "0"}
        </div>
      </div>
      {expanded && player?.breakdown.length ? (
        <div className="pb-4">
          {player?.breakdown.map((b, index) => (
            <div key={index} className="flex justify-between pl-8">
              <div className="text-xs">{b.title}</div>
              <div className="text-xs">{b.score}</div>
            </div>
          ))}
        </div>
      ) : null}
    </li>
  );
};
