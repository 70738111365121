import { ComponentProps } from "react";
import { clsx } from "clsx";
import { Spinner } from "./Spinner";

type Props = ComponentProps<"button"> & {
  pending?: boolean;
  mode?: "primary" | "secondary";
};

export const Button = ({
  className,
  children,
  pending = false,
  mode = "primary",
  type = "button",
  ...rest
}: Props) => {
  const styles =
    mode === "primary"
      ? "bg-[#0064c8] hover:bg-[#0078c8] text-[#ffffff]"
      : "bg-[#eeeeee] hover:bg-[#ffffff] text-[#0064c8]";

  return (
    <button
      {...rest}
      type={type}
      className={clsx(
        className,
        styles,
        "min-w-56 border-none rounded px-8 py-2 cursor-pointer",
        "transition-colors",
        "flex items-center justify-center gap-2"
      )}
      disabled={pending}
    >
      <Spinner enabled={pending} size="sm" /> {children}
    </button>
  );
};
