import { useMutation } from "@tanstack/react-query";
import { Button } from "../components/utils/Button";
import { post } from "../api/api";
import { useNavigate } from "react-router-dom";
import { Input } from "../components/utils/Input";
import { useState } from "react";
import { EmojiPickInput } from "../components/emoji/EmojiPickInput";
import { useRequiredParam } from "../hooks/useRequiredParam";
import { JoinGameRequest } from "../api/schema";

const joinGameFn = (params: JoinGameRequest) => post("/join-game", params);

export function Join() {
  const navigate = useNavigate();
  const gameId = useRequiredParam("gameId");

  const [name, setName] = useState("");
  const [emoji, setEmoji] = useState("");

  const joinGame = useMutation({ mutationFn: joinGameFn });

  return (
    <div className="flex flex-col gap-8 my-10 items-center">
      <h1>Taking Part 🎖️</h1>
      {joinGame.error?.message ? (
        <p className="text-red-200">{joinGame.error.message}</p>
      ) : null}
      <form
        className="flex flex-col gap-4 items-center"
        onSubmit={(e) => {
          e.preventDefault();
          joinGame.mutate(
            { name: name.trim(), emoji, gameId },
            { onSuccess: () => navigate(`/play/${gameId}`) }
          );
        }}
      >
        <Input
          name="name"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.currentTarget.value)}
          required
        />
        <EmojiPickInput emoji={emoji} setEmoji={setEmoji} />
        <Button type="submit" mode="primary" pending={joinGame.isPending}>
          Join
        </Button>
      </form>
    </div>
  );
}
