import { useMemo } from "react";

export const useFallback = <T>(
  value: T[] | null | undefined,
  count: number,
  getKey: (value: T, index: number) => string
): { key: string; value?: T }[] =>
  useMemo(
    () =>
      value?.map((value, index) => ({ key: getKey(value, index), value })) ??
      new Array(count)
        .fill(null)
        .map((_, index) => ({ key: index.toString() })),
    [value, count]
  );
