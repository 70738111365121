import * as v from "valibot";

export const PostRequests = {
  "/start-game": {
    request: v.object({ name: v.string(), emoji: v.string() }),
    response: v.object({
      gameId: v.string(),
    }),
  },
  "/join-game": {
    request: v.object({
      name: v.string(),
      emoji: v.string(),
      gameId: v.string(),
    }),
    response: v.object({}),
  },
  "/add-score": {
    request: v.object({
      gameId: v.string(),
      playerId: v.string(),
      title: v.string(),
      score: v.number(),
    }),
    response: v.object({}),
  },
} as const;

export type PostRequests = typeof PostRequests;

export type JoinGameRequest = v.Input<PostRequests["/join-game"]["request"]>;
export type JoinGameResponse = v.Output<PostRequests["/join-game"]["response"]>;

export type StartGameRequest = v.Input<PostRequests["/start-game"]["request"]>;
export type StartGameResponse = v.Output<
  PostRequests["/start-game"]["response"]
>;

export type AddScoreRequest = v.Input<PostRequests["/add-score"]["request"]>;
export type AddScoreResponse = v.Output<PostRequests["/add-score"]["response"]>;

export const GetRequests = {
  "/leaderboard": {
    params: v.object({ gameId: v.string() }),
    response: v.object({
      players: v.array(
        v.object({
          id: v.string(),
          name: v.string(),
          emoji: v.string(),
          score: v.number(),
          breakdown: v.array(
            v.object({
              title: v.string(),
              score: v.number(),
            })
          ),
        })
      ),
    }),
  },
} as const;

export type GetRequests = typeof GetRequests;
export type LeaderboardResponse = v.Output<
  GetRequests["/leaderboard"]["response"]
>;
