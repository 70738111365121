import { useRouteError, isRouteErrorResponse } from "react-router-dom";

export function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  if (isRouteErrorResponse(error) && error.status === 404) {
    return (
      <div>
        <h1>Page Not Found</h1>
        <p>
          We apologize, but the page you are looking for could not be found.
        </p>
        <a href="/">Go back to homepage</a>
      </div>
    );
  }

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>
        <i>
          {isRouteErrorResponse(error)
            ? error.statusText
            : error instanceof Error
            ? error.message
            : null}
        </i>
      </p>
    </div>
  );
}
