import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { get } from "../../api/api";
import clsx from "clsx";
import { useFallback } from "../../utils/use-fallback";
import { PlayerRow } from "./PlayerRow";
import { useMemo } from "react";

type Props = {
  gameId: string;
};

const getLeaderboard = async ({
  queryKey,
}: QueryFunctionContext<[string, string]>) =>
  get("/leaderboard", { gameId: queryKey[1] });

export const Leaderboard = ({ gameId }: Props) => {
  const { data, isPending, error } = useQuery({
    queryKey: ["leaderboard", gameId],
    queryFn: getLeaderboard,
    refetchInterval: 10_000,
  });

  const players = useFallback(data?.players, 5, (player) => player.id);

  const sortedPlayers = useMemo(() => {
    return players.sort((a, b) => {
      if (a.value?.score == b.value?.score) {
        return 0;
      }

      return (a.value?.score ?? 0) > (b.value?.score ?? 0) ? -1 : 1;
    });
  }, [players]);

  return (
    <div className="px-4 pt-4 pb-2 max-w-md m-auto my-10 border border-gray-200 rounded shadow">
      <h1 className="text-2xl font-bold mb-4">Leaderboard 🎖️</h1>
      {error?.message ? (
        <p className="text-red-300">{error.message}</p>
      ) : (
        <ol
          className={clsx("pt-2 divide-y divide-gray-200", {
            "animate-pulse": isPending,
          })}
          aria-busy={isPending}
        >
          {sortedPlayers.map(({ value: player, key }) => (
            <PlayerRow key={key} player={player} gameId={gameId} />
          ))}
        </ol>
      )}
    </div>
  );
};
