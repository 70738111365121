import { useCallback, useState } from "react";

export const useCopy = (text: string) => {
  const [copied, setCopied] = useState(false);

  return {
    copied,
    copy: useCallback(() => {
      navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }, [text]),
  };
};
